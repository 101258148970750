<template>
  <upload-nsi-list
    :import-type-id="importTypeId"
    :file-text="fileText"
    :remote-parse-method="remoteParseMethod"
  />
</template>

<script>
import uploadNsiList from '@/views/Administration/UploadNsi/UploadNsiList';
import uploadNsiApi from '@/api/administration/uploadNsi';

export default {
name: 'AnalysisMappingUploads',
  components: { uploadNsiList },
  data() {
    return {
      importTypeId: 201,
      fileText: 'Выберите zip архив с excel-файлами маппинга',
      remoteParseMethod: uploadNsiApi.parseAnalysisMapping,
    };
  },
};
</script>

<style scoped>

</style>
